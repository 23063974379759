/* Redefine Custom Progress Bar */
.CircularProgressbar-text{
    font-family: 'Oswald', sans-serif !important;
}

/* Custom */
.padd-left-0{
    padding-left: 0 !important;
}
.padd-right-0{
    padding-right: 0 !important;
}
.marg-left-300{
    margin-left: 300px !important;
}

/* Navigation menu */
.nav-list {
    list-style-type: none;
    height: 100%;
    display: inline-flex;
    align-items: center;
}
.nav-language{
    width: 100px;
    opacity: 0.5;
}
.btn {
    display: none;
}
.items {
    margin-right: 50px;
    cursor: pointer;
}
.items a{
    color: var(--text-color) !important;
    text-decoration: none;
    white-space: nowrap;
}
.items a:hover{
    color: var(--link-clean-hover-color) !important;
}
@media screen and (max-width: 576px){
    .nav-list {
        position: absolute;
        display: block;
        z-index: 9999;
        padding: 5%;
        margin: 0 5%;
        top: 70px;
        left: 0;
        width: 90%;
        height: auto;
        background: rgba(255, 255, 255, 0.7);
        border-radius: 20px;
    }
    .items {
        width: 100%;
        text-align: center;
        margin: 0px;
        padding: 20px 0;
    }
    .btn {
        display: block;
        position: absolute;
        right: 10px;
        top: 7px;
        padding: 5px;
        color: #000;
        border: none !important;
    }
}